<template>
	<!-- [DEV] 데이터 없는 경우 공통 -->
	<div class="no-results-found" v-if="sprtBizList.length == 0">
		<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
	</div>
	<div v-else> 
		<div class="reserv-thumbnail-list">
			<article class="sptpgm-article" v-for="(sprtBiz, idx) in sprtBizList" :key="idx">
				<!-- <div class="sptpgm-reserv-img">
					<span class="thumb-default">
						<img src="@/assets/img/@tmp/tmp_reserv3.jpg" :alt="item.title" />
					</span>
				</div> -->
				<div class="sptpgm-reserv-text">
					<header class="sptpgm-article-header">
						<div class="fl">
							<span class="sptpgm-article-header__tag is-category">{{ sprtBiz.sprtBizTyNm }}</span>
							<span class="sptpgm-article-header__tag">{{ sprtBiz.sprtBizTrgtNm }}</span>
							<span class="sptpgm-article-header__tag">{{ sprtBiz.sprtBizCtpvNm }}</span>
						</div>
						<div class="fr">
							<span class="sptpgm-article-header__dday">D-{{ sprtBiz.dday }}</span>
						</div>
					</header>
					<div class="sptpgm-article-body">
						<h3 class="sptpgm-article-title">{{ sprtBiz.sprtBizNm }}</h3>
					</div>
					<footer class="sptpgm-article-footer display-flex">
						<dl class="sptpgm-article-dl">
							<dt><span class="sr-only">기간</span></dt>
							<dd>{{ sprtBiz.rcritBgngYmd }} ~ {{ sprtBiz.rcritEndYmd }}</dd>
						</dl>
						<div class="buttons buttons-flex justify-content-end">
							<template v-if="isOpen(sprtBiz.sprtBizEndYmd)">
								<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${sprtBiz.sprtBizCd}`" target="_blank" class="button-default is-medium is-primary is-rounded">신청</a>
							</template>
							<template v-else>
								<span class="button-default is-primary is-medium is-rounded is-disabled">마감</span>
							</template>
						</div>
					</footer>
				</div>
			</article>
		</div>
		<div class="reserv-pagination-wrap">
			<!-- pagination -->
			<portal-pagination page-name="SpaceProgram" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @pagingChange="pagingChange"></portal-pagination>
            <!-- //pagination -->
		</div>
	</div>
</template>

<script>
import { ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST } from '@/store/_act_consts';
import { mapGetters } from 'vuex';
import PortalPagination from '@/components/PortalPaginationRoute';
import {
	dateToDateFormat,
	getCheckItems,
	getItems,
	numberComma,
	setPaging, tagRemove,
	timestampToDateFormat,
	ymdToFormat
} from '@/assets/js/utils';

export default {
	name: 'SpaceProgram',
	components: { PortalPagination },
	props: {
		initTabScrollFnNm: String,
		info: {
			type: Object,
			default: {},
		},
	},
	computed: {},
	mounted() {},
	data: () => ({
		totalCount: 10,
		pageSize: 10,
		pageNo: 1,
		pageListSize: 10,
		sprtBizList: [],
		pagingMethodName: 'pagingChange',
		queries: {},
		spaceId: 0,
	}),
	created() {
		this.spaceId = this.info.spaceId;
		this.getSprtBizList();
	},
	methods: {
        getSprtBizList() {
				
			this.$store
				.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_SEARCH_LIST}`, {
					sprtBizCg2Nm: '온라인 기반구축',
					sprtBizCg3Nm: '소담스퀘어',
					spaceId: this.spaceId,
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					//sprtBizCtpvNm: this.mssArea === '전국' ? '' : this.mssArea,
					//sprtBizCtpvNm: '',
					//sprtBizTyNm: '',
				})
				.then((res) => {
					this.sprtBizList = getCheckItems(res);
					setPaging(this, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		pagingChange(no) {
			this.pageNo = no;
			this.$emit(this.initTabScrollFnNm);
      		this.getSprtBizList();
		},
		isOpen(endYmd) {
			let endDate = this.toDate(endYmd);
			return  endDate >= this.toDate(dateToDateFormat(new Date(), 'yyyy-MM-dd'));
		},
		toDate(date_str)
		{
			var yyyyMMdd = String(date_str);
			var sYear = yyyyMMdd.substring(0,4);
			var sMonth = yyyyMMdd.substring(5,7);
			var sDate = yyyyMMdd.substring(8,10);

			return new Date(Number(sYear), Number(sMonth)-1, Number(sDate));
		},
    },
};
</script>
