<template>
	<div class="layer-popup-container">
		<!-- [layer-popup-mini:type1] -->
		<div class="layer-popup popup-share" :class="{ 'gachi-mobile-popup': isMobile, 'layer-md-popup': !isMobile }">
			<div class="popup-wrapper">
				<header class="popup-header">
					<button type="button" class="btn-close" @click="onClickClosePopupButton">
						<i class="icon-close">팝업닫기</i>
					</button>
				</header>
				<div class="popup-body" style="margin-bottom: 20px">
					<div class="share-list">
						<button type="button" class="btn-share" @click="onClickKakaoLinkButton">
							<i class="icon-share-kakao"></i>
							<span class="btn-share-text">카카오톡</span>
						</button>
						<button type="button" class="btn-share" @click="onClickShareUrl">
							<i class="icon-share-link"></i>
							<span class="btn-share-text">링크공유</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { ACT_GET_UTIL_SHORT_URL } from '@/store/_act_consts';
import { mapGetters } from 'vuex';

export default {
	name: 'SpaceSharePop',
	components: {},
	props: {
		content: Object,
	},
	watch: {},
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	data: () => ({
		url: window.document.location.href,
	}),
	methods: {
		// promise
		async createShortUrl() {
			await this.$store
				.dispatch(`main/${ACT_GET_UTIL_SHORT_URL}`, { url: this.url })
				.then((res) => {
					if (res.result.success) {
						const url = JSON.parse(res.data).result.url;
						navigator.clipboard.writeText(url).then(() => {
							// window.alert('URL이 클립보드에 복사되었습니다.');
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: 'URL이 클립보드에 복사되었습니다.' });
						});
					} else {
						console.info(res.result.message);
						// window.alert('URL생성에 실패 하였습니다.');
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: 'URL생성에 실패 하였습니다.' });
					}
				})
				.catch((err) => {
					console.log(err);
					// window.alert('URL생성에 실패 하였습니다.');
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: 'URL생성에 실패 하였습니다.' });
				});

			this.onClickClosePopupButton();
		},

		// html event
		onClickClosePopupButton() {
			this.$emit('close');
		},
		onClickShareUrl() {
			this.createShortUrl();
		},
		onClickKakaoLinkButton(e) {
			if (!Kakao.isInitialized()) {
				Kakao.init(`${process.env.VUE_APP_KAKAO_JS_KEY}`);
			}
			const feed = {
				objectType: 'feed',
				content: this.content,
				buttons: [
					{
						title: '웹으로 이동',
						link: {
							mobileWebUrl: this.url,
							webUrl: this.url,
						},
					},
				],
			};
			Kakao.Link.sendDefault(feed);
			this.onClickClosePopupButton();
		},
	},
};
</script>
