<template>
	<div class="layer-popup-container">
		<!-- [layer-popup-mini:type1] -->
		<div class="layer-popup popup-map" :class="{ 'gachi-mobile-popup': isMobile, 'layer-md-popup': !isMobile }">
			<div class="popup-wrapper">
				<header class="popup-header">
					<button type="button" class="btn-close" @click="onClickClosePopupButton">
						<i class="icon-close">팝업닫기</i>
					</button>
				</header>
				
				<div class="popup-body" style="margin-bottom: 20px">
					<div class="popup-map-flexwrap">
						<div class="popup-map-container">
							<div id="map" class=""></div>
						</div>
						<div class="popup-body-dls">
							<dl class="popup-body-dl">
								<dt>주소</dt>
								<dd>{{ info.bizplMeta.addr.trim() + ' (' + this.info.bizplMeta.addrDtl.trim() + ')' }}</dd>
							</dl>
							<dl class="popup-body-dl">
								<dt>전화번호</dt>
								<dd>{{ info.bizplMeta.telNo }}</dd>
							</dl>
							<dl class="popup-body-dl">
								<dt>오시는 길</dt>
								<dd>{{ info.bizplMeta.guideCn }}</dd>
							</dl>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'SpaceMapPop',
	components: {},
	props: {
		info: {
			type: Object,
			default: {},
		},
	},
	watch: {},
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	data: () => ({
		// url: window.document.location.href,
		// LAT_DEFAULT: 37.5291722,    // 행복한백화점
    	// LNG_DEFAULT: 126.8759793,   // 행복한백화점
	}),
	created() {},
	mounted() {
		this.loadKakaoMap()
		document.addEventListener('keyup', this.onPressEsc)
	},
	beforeDestroy() {
		document.removeEventListener('keyup', this.onPressEsc)
	},
	methods: {
		// event ===============================================================
		onClickClosePopupButton() {
			this.$emit('close')
		},
		onPressEsc(e) {
			if (e.key === 'Escape' || e.keyCode === 27) { 
				this.$emit('close')
			}
		},

		// custom fn ===============================================================
		loadKakaoMap() {
			if (!!window.kakao && !!window.kakao.maps) {
				this.getGeoInfo()
			}
			else {
				const script = document.createElement('script')
				/* global kakao */
				script.onload = () => kakao.maps.load(this.getGeoInfo)
				script.src = `https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=${process.env.VUE_APP_KAKAO_JS_KEY}&libraries=services`
				document.head.appendChild(script)
			}
		},

		// promise ===============================================================
		async getGeoInfo() {
			const geocoder = new kakao.maps.services.Geocoder()
			
			const callback = (result, status) => {

				if (status === kakao.maps.services.Status.OK) {
					const lat = result[0].y
					const lng = result[0].x
					const coords = new kakao.maps.LatLng(lat, lng)

					// initiating a map
					const container = document.getElementById('map')
					const options = {
						center: new kakao.maps.LatLng(lat, lng),
						level: 3,
					}
					const map = new kakao.maps.Map(container, options)

					// 결과값으로 받은 위치를 마커로 표시합니다
					const marker = new kakao.maps.Marker({
						// map: map,
						position: coords
					})
					marker.setMap(map)

					// // 인포윈도우로 장소에 대한 설명을 표시합니다
					// const infowindow = new kakao.maps.InfoWindow({
					// 	content: '<div style="width:150px;text-align:center;padding:6px 0;">우리회사</div>'
					// });
					// infowindow.open(map, marker);

					// // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
					// map.setCenter(coords);
				}
			}
			geocoder.addressSearch(this.info.bizplMeta.addr, callback)
		},
	},
};
</script>
