<template>
	<div class="no-results-found" v-if="noticeList.length == 0">
		<p class="text">공지사항이 존재하지 않습니다.</p>
	</div>
	<div v-else> 
		<div class="reserv-notice-list">
			<ul>
				<li v-for="(item, idx) in noticeList" :key="idx">
					<router-link :to="`${info.spaceId}?tab=notice-view&ntcId=${item.ntcId}`" class="textlist-item">
						<div class="textlist-item-header">
							<span v-if="item.imprtYn === 'Y'" class="textlist-item__tag">중요</span>
							<strong class="textlist-item__title">{{ item.tit }}</strong>
						</div>
						<!-- <p class="textlist-item__desc">{{ item.memoCn }}</p> -->
						<span class="textlist-item__date">{{ regDtTimeFormat(item.regDt) }}</span>
					</router-link>
				</li>
			</ul>
		</div>
		<div class="reserv-pagination-wrap">
			<portal-pagination page-name="SpaceNotice" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @pagingChange="pagingChange"></portal-pagination>
		</div>
	</div>
</template>

<script>
import PortalPagination from '@/components/PortalPaginationRoute';
import { ACT_GET_SPACE_NOTICE_LIST } from '@/store/_act_consts';
import { getItems, setPaging, timestampToDateFormat } from '@/assets/js/utils';
export default {
	name: 'SpaceNotice',
	components: { PortalPagination },
	props: {
		info: {
			type: Object,
			default: {},
		},
		initTabScrollFnNm: String,
	},
	computed: {},
	data: () => ({
		totalCount: 10,
		pageSize: 10,
		pageNo: 1,
		pageListSize: 10,
		pagingMethodName: 'pagingChange',
		queries: {},
		noticeList: [],
	}),
	created() {
		this.getSpaceNoticeList();
	},
	methods: {
		getSpaceNoticeList() {
			const payload = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
				spaceId: this.info.spaceId,
			};
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_NOTICE_LIST}`, payload)
				.then((res) => {
					if (this.noticeList.length > 0) this.noticeList.length = 0;
					this.noticeList = this.noticeList.concat(getItems(res));
					setPaging(this, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		pagingChange(no) {
			this.pageNo = no;
			this.$emit(this.initTabScrollFnNm);
			this.getSpaceNoticeList();
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy-MM-dd');
		},
	},
};
</script>
