<template>
	<main class="page-container" id="reservation-detail">
		<page-header :title="item.spaceNm" :prevPath="prevRoutePath" :showMyRsvtBtn="showMyRsvtBtn" />

		<div class="page-component">
			<div class="reserv-detail-utils">
				<button type="button" class="button" @click="setFavr()">
					<i :class="[isFavorite ? 'icon-reserv-fav-on' : 'icon-reserv-fav']"></i>
					<span class="sr-only">즐겨찾기</span>
				</button>
				<button type="button" class="button" @click="onClickSharePopupButton()">
					<i class="icon-reserv-share"></i>
					<span class="sr-only">공유</span>
				</button>
				<button type="button" class="button" @click="onClickMapPopupButton()">
					<i class="icon-reserv-map"></i>
					<span class="sr-only">지도</span>
				</button>
			</div>
			<!-- 공유 팝업 -->
			<share-pop v-if="visibleSharePopup" @close="onCloseSharePopupButton" :content="shareContent" />
			<!-- //공유 팝업 -->
			<!-- 지도 팝업 -->
			<map-pop v-if="visibleMapPopup" :info="info" @close="onCloseMapPopupButton" />
			<!-- //지도 팝업 -->

			<div class="reserv-detail-header">
				<div class="reserv-detail-header__gallery">
					<ul>
						<li v-for="(glry, idx) in defaultGlryItems" :key="idx">
							<a @click.prevent="onClickShowImg(glryItems, idx)">
								<span v-if="totalCount > 5 && idx == 4" class="thumb-more">
									<i class="icon-thumb-more"></i>
									<em> + {{ totalCount - 5 }}</em>
								</span>
								<span class="thumb">
									<img v-if="glry.imgFileUrl" :src="glry.imgFileUrl" :alt="glry.imgFileNm" @error="handleImgError" />
									<img v-else src="@/assets/img/common/noimage.png" alt="" @error="handleImgError" />
								</span>
							</a>
						</li>
					</ul>
				</div>
				<ul class="reserv-detail-sodaminfo">
					<li ref="tabNav">
						<span class="display-flex"><i class="icon-sodam-map"></i>{{ addr }}</span>
					</li>
					<li v-if="!!openDtDesc.oper" class="hidden-mobile">
						<span class="display-flex"><i class="icon-sodam-time"></i>{{ openDtDesc.oper }}</span>
					</li>
					<li v-if="!!openDtDesc.impsbTimes">
						<span class="display-flex"><i class="icon-sodam-reserv"></i>{{ openDtDesc.impsbTimes }}</span>
					</li>
					<li v-if="!!openDtDesc.holdyOrWekend">
						<span class="display-flex"><i class="icon-sodam-reserv"></i>{{ openDtDesc.holdyOrWekend }}</span>
					</li>
					<li v-if="!!item.telNo">
						<span class="display-flex"><i class="icon-sodam-phone"></i>{{ item.telNo }}</span>
					</li>
					<li>
						<a v-for="(sns, idx) in openDtDesc.snsList" :key="idx" :href="sns.chnlUrl" class="display-flex"><i class="icon-sodam-url"></i>{{ sns.chnlUrl }}</a>
					</li>
				</ul>
			</div>
			<div class="buttons buttons-flex" @click.stop>
				<a :href="`tel:${item.telNo}`" class="button-default is-large is-outlined is-rounded mobile-show">전화</a>
				<button class="button-default is-large is-outlined is-rounded" @click.prevent="goInq()">문의</button>
				<router-link :to="`/space/${spaceId}?tab=reserv`" class="button-default is-large is-primary is-rounded">예약</router-link>
			</div>
			<div class="reserv-detail-nav">
				<nav class="page-nav">
					<ul class="nav-list">
						<li class="nav-item" :class="{ 'is-selected': activeNav === idx }" v-for="(item, idx) in navList" :key="idx">
							<button type="button" class="nav-text" @click="navigateTo(item.to, idx)">{{ item.text }}</button>
						</li>
					</ul>
				</nav>
			</div>

			<space-gallery-swiper ref="gallerySwiper"></space-gallery-swiper>

			<div class="reserv-detail-content">
				<!-- params 에 따라 동적으로 컴포넌트 로딩 -->
				<component :is="`space-${componentName}`" :info="info" :init-tab-scroll-fn-nm="initTabScrollFnNm" @initTabScroll="initTabScroll" />
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SPACE_INFO, ACT_GET_SPACE_GLRY_DTL_LIST, ACT_GET_SPACE_FAVR, ACT_UPDATE_SPACE_FAVR } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM } from '@/store/_mut_consts';

import { getItems, lengthCheck, getItem, getCheckItems, hhmmToDateFormat, setPaging, isSuccess } from '@/assets/js/utils';
import PageHeader from '@/components/space/PageHeader.vue';
import SharePop from '@/components/space/SharePop';
import MapPop from '@/components/space/MapPop';
import errorImg from '@/assets/img/common/error.png';
// 동적 컴포넌트
import SpaceIntro from '@/components/space/Intro.vue'; // 탭 - 소개
import SpaceReserv from '@/components/space/Reserv.vue'; // 탭 - 예약
import SpaceProgram from '@/components/space/Program.vue'; // 탭 - 프로그램
import SpaceGallery from '@/components/space/Gallery.vue'; // 탭 - 갤러리
import SpaceNotice from '@/components/space/Notice.vue'; // 탭 - 공지
import SpaceNoticeView from '@/components/space/NoticeView.vue'; // 탭 - 공지상세
import SpaceGallerySwiper from '@/components/space/GallerySwiper.vue'; // 탭 - 갤러리 스와이퍼

export default {
	name: 'SpaceDetail',
	components: { PageHeader, SharePop, MapPop, SpaceIntro, SpaceReserv, SpaceProgram, SpaceGallery, SpaceNotice, SpaceNoticeView, SpaceGallerySwiper },
	watch: {},
	beforeRouteUpdate(to, from, next) {
		const tab = to.query.tab;
		if (tab != from.query.tab) {
			try {
				this.loadComponent(tab);
				if (tab !== 'intro') {
					this.initTabScroll();
				}

				if (tab === 'reserv-view') {
					this.activeNav = 2;
				} else if (tab === 'notice-view') {
					this.activeNav = 4;
				} else {
					this.activeNav = this.findIndexInNavList(tab);
				}
			} catch (error) {
				console.log(error);
			}
		}
		next();
	},
	computed: {
		...mapGetters('common', ['isMobile', 'commonCode']),
		...mapGetters('auth', ['session', 'isAuth']),
		openDtDesc() {
			const item = this.item;

			// 10:00 ~ 20:00 / 12~13시 예약불가 / 주말, 공휴일 휴무
			let oper = '운영시간 : ';
			if (item.operStrtHhmm) oper += hhmmToDateFormat(item.operStrtHhmm, ':') + ' ~ ' + hhmmToDateFormat(item.operEndHhmm, ':');

			// 예약불가
			let impsbTimes = '';
			if (item.spaceImpsbTimes && item.spaceImpsbTimes.length > 0) {
				impsbTimes = '이용불가 시간 : ';

				item.spaceImpsbTimes.split(',').forEach((d, idx) => {
					if (idx > 0) impsbTimes += ' | ';
					d.split('|').forEach((e, j) => {
						impsbTimes += hhmmToDateFormat(e, ':') + (j === 0 ? ' ~ ' : '');
					});
				});
			}

			// 주말, 공휴일 휴무
			let holdyOrWekend = '';
			if (item.holdyOperYn == 'N' || item.wekendOperYn == 'N') {
				holdyOrWekend = '휴무일 : ';
				if (item.holdyOperYn == 'N') {
					holdyOrWekend += '주말';
				}
				if (item.wekendOperYn == 'N') {
					holdyOrWekend += item.holdyOperYn == 'N' ? ' 및 공휴일' : '공휴일';
				}
			}

			return {
				oper: oper,
				impsbTimes: impsbTimes,
				holdyOrWekend: holdyOrWekend,
				snsList: item.snsList,
			};
		},
		addr() {
			return `${this.item.addr ?? ''} ${this.item.addrDtl ?? ''}`;
		},
		shareContent() {
			let url = window.location.origin + window.location.pathname;

			return {
				title: this.item.spaceNm ?? '',
				description: '',
				imageUrl: this.glryItems[0]?.imgFileUrl ?? '',
				link: {
					mobileWebUrl: url,
					webUrl: url,
				},
			};
		},
		defaultGlryItems(){
			if(this.glryItems && this.glryItems.length > 5) return this.glryItems.slice(0, 5);
			else return this.glryItems;
		},
	},
	data: () => ({
		spaceId: 0,
		//TAB 사용할 데이터 OBJ
		info: {
			spaceId: 0, //각 tab에서 사용할 spaceId
			//소개에서 사용할 데이터
			intro: {
				contents: {},
				snsList: [],
			},
			bizplMeta: {
				addr: '',
				addrDtl: '',
				telNo: '',
				guideCn: '',
			},
		},
		// 상단 상세
		item: {},
		//상세 상단 갤러리
		glryItems: [],
		hasMore: false,
		pageNo: 1,
		pageSize: 99,
		totalCount: 0,
		initTabScrollFnNm: 'initTabScroll',
		// 동적 컴포넌트 관련 - 시작
		activeNav: 0,
		visibleSharePopup: false,
		visibleMapPopup: false,
		isFavorite: false,
		componentName: 'intro',
		navList: [
			{
				text: '소개',
				to: 'intro',
			},
			{
				text: '예약',
				to: 'reserv',
			},
			{
				text: '프로그램',
				to: 'program',
			},
			{
				text: '갤러리',
				to: 'gallery',
			},
			{
				text: '공지',
				to: 'notice',
			},
		],
		// 동적 컴포넌트 관련 - 끝
		prevRoutePath: null,
		showMyRsvtBtn: true,
	}),
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			vm.prevRoutePath = from.path;
		});
	},
	created() {
		this.info.spaceId = this.spaceId = this.$route.params.spaceId;
		this.getInfo();
		this.getGlryDtls();
		this.getFavr();
	},
	mounted() {
		const tab = this.$route.query.tab ?? this.navList[0].to;
		this.$nextTick(() => {
			if (tab === 'reserv-view') {
				this.activeNav = 2;
			} else if (tab === 'notice-view') {
				this.activeNav = 4;
			} else {
				this.activeNav = this.findIndexInNavList(tab);
			}

			if (tab !== 'intro') {
				try {
					this.loadComponent(tab);
					this.initTabScroll();
				} catch (error) {
					console.log(error);
				}
			}
		});
	},
	methods: {
		initTabScroll() {
			setTimeout(() => {
				this.$refs.tabNav.scrollIntoView();
			}, 100);
		},
		// 상세 조회
		getInfo() {
			this.$store.dispatch(`space/${ACT_GET_SPACE_INFO}`, this.info.spaceId).then((res) => {
				if (lengthCheck(res)) {
					// console.log('[ACT_GET_SPACE_INFO] -> ', res);
					this.item = getItem(res);
					//소개TAB
					this.info.intro.contents = this.item.introCn;
					this.info.intro.snsList = this.item.snsList;

					this.info.bizplMeta = {
						addr: this.item.addr,
						addrDtl: this.item.addrDtl,
						telNo: this.item.telNo,
						guideCn: this.item.guideCn,
					};
				}
			});
		},
		// 상세 갤러리 조회
		getGlryDtls() {
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_GLRY_DTL_LIST}`, {
					spaceId: this.spaceId,
					glryId: 0,
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					this.glryItems = getItems(res);
					setPaging(this, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		// 즐겨찾기 여부
		getFavr() {
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_FAVR}`, this.spaceId)
				.then((res) => {
					const item = getCheckItems(res);
					this.isFavorite = item.length > 0 && item[0].useYn == 'Y';
				})
				.catch((e) => {
					console.error(e);
				});
		},
		// 즐겨찾기 설정
		setFavr() {
			if (!this.chkLogin()) return false;

			// api 호출
			this.$store
				.dispatch(`space/${ACT_UPDATE_SPACE_FAVR}`, {
					spaceId: this.spaceId,
					params: {
						useYn: !this.isFavorite ? 'Y' : 'N',
					},
				})
				.then((res) => {
					if (isSuccess(res)) {
						this.isFavorite = !this.isFavorite;
						let text = this.isFavorite ? '즐겨찾는 공간으로 등록되었습니다' : '즐겨찾는 공간에서 해제되었습니다.';
						this.$toast.open(text);
					} else {
						let text = !this.isFavorite ? '즐겨찾는 공간으로 등록 실패 하였습니다.' : '즐겨찾는 공간으로 해제 실패 하였습니다.';
						this.$toast.open(text);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		goInq() {
			if (this.chkLogin()) {
				// this.$router.push({ name:'ReservSpaceInquiry', query: {spaceId: this.spaceId} });
				this.$router.push({ name: 'SpaceRsvtInquiryInput', params: { spaceId: this.spaceId } });
			}
		},
		chkLogin() {
			if (typeof this.session.userId == 'undefined') {
				const param = {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: false,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path + (this.$route.query.tab == undefined ? '' : '?tab=' + this.$route.query.tab));
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {},
				};
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
				return false;
			}

			return true;
		},
		// 동적 컴포넌트 관련 - 시작
		findIndexInNavList(toValue) {
			return this.navList.findIndex((item) => item.to === toValue);
		},
		// toggleFavorite() {
		// 	this.isFavorite = !this.isFavorite;
		// 	let text = this.isFavorite ? '즐겨찾는 공간으로 등록되었습니다' : '즐겨찾는 공간에서 해제되었습니다.';
		// 	let instance = this.$toast.open(text);
		// },
		onClickSharePopupButton() {
			this.visibleSharePopup = true;
		},
		onCloseSharePopupButton() {
			this.visibleSharePopup = false;
		},
		onClickMapPopupButton() {
			this.visibleMapPopup = true;
		},
		onCloseMapPopupButton() {
			this.visibleMapPopup = false;
		},
		navigateTo(to, idx) {
			// this.$refs.tabNav.scrollIntoView({ behavior: 'smooth' });
			this.$router.push({ query: { tab: to } }).catch(() => {});
			// "program", "gallery", "notice" 내예약버튼 숨김처리
			this.showMyRsvtBtn = !['program', 'gallery', 'notice'].includes(to);
			// this.activeNav = idx;
			// this.loadComponent(to);
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
		loadComponent(name) {
			if (name === undefined) this.componentName = this.navList[0].to;
			else this.componentName = name;
		},
		// 동적 컴포넌트 관련 - 끝
		// event ===============================================================
		onClickShowImg(glryDtlList = [], idx = 0) {
			if (this.totalCount > 4) {
				//5개 이상일 경우 더보기
				if (idx == 4 && this.totalCount > 5) this.$router.push({ query: { tab: 'gallery' } }).catch(() => {});
				else if (this.glryItems.length == 5) this.$refs.gallerySwiper.onClickShowImg(this.glryItems, idx);
				else this.$refs.gallerySwiper.onClickShowImg(this.glryItems, idx);
			} else this.$refs.gallerySwiper.onClickShowImg(this.glryItems, idx);
		},
		onClickHide() {
			this.$refs.gallerySwiper.onClickHide();
		},
	},
};
</script>
