<template>
	<div>
		<div class="textlist-detail-header">
			<div class="textlist-item-header display-block">
				<span class="textlist-item__tag" v-if="params.imprtYn === 'Y'">중요</span>
				<strong class="textlist-item__title textlist-item__title-full">{{ params.tit }}</strong>
			</div>
		</div>
		<div class="textlist-detail-body">
			<div class="content" v-html="params.memoCn"></div>
		</div>
		<div class="textlist-detail-footer">
			<div class="textlist-detail-footer__attach" style="cursor: pointer" @click="atchDown">[첨부파일] {{ params.atchFileNm }}</div>
			<div class="textlist-detail-footer__date">{{ regDtTimeFormat(params.regDt) }}</div>
		</div>

		<div class="buttons flex align-items-center justify-content-center gap-10">
			<button type="button" class="button-default is-large is-rounded is-gray" v-if="prevId" @click="onClickMoveInfo(prevId)">이전</button>
			<router-link :to="`/space/${spaceId}?tab=notice`" class="button-default is-large is-rounded is-secondary">목록</router-link>
			<button type="button" class="button-default is-large is-rounded is-gray" v-if="nextId" @click="onClickMoveInfo(nextId)">다음</button>
		</div>
	</div>
</template>

<script>
import { ACT_GET_SPACE_NOTICE_INFO, ACT_GET_SPACE_NOTICE_LIST } from '../../store/_act_consts';
import { lengthCheck, getItem, timestampToDateFormat, getItems, queryToValue } from '@/assets/js/utils';
export default {
	name: 'ReservDetailNoticeView',
	components: {},
	props: {},
	computed: {},
	data: () => ({
		ntcId: 0,
		spaceId: 0,
		params: {},
		noticeList: [],
		prevId: 0,
		nextId: 0,
	}),
	watch: {
		$route(to) {
			this.ntcId = queryToValue(to.query.ntcId, true);
			this.getSpaceNotice();
		},
	},
	mounted() {
		this.ntcId = this.$route.query.ntcId;
		this.spaceId = this.$route.params.spaceId;
		this.getSpaceNoticeList();
	},
	methods: {
		getSpaceNoticeList() {
			const payload = {
				pageNo: 1,
				pageSize: 9999,
				spaceId: this.spaceId,
			};
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_NOTICE_LIST}`, payload)
				.then((res) => {
					this.noticeList = getItems(res);
					this.getSpaceNotice();
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getSpaceNotice() {
			const payload = {
				ntcId: this.ntcId,
			};
			// api 호출
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_NOTICE_INFO}`, payload)
				.then((res) => {
					if (lengthCheck(res)) {
						this.params = getItem(res);
						this.settingMoveNotice();
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		settingMoveNotice() {
			const findIdx = this.noticeList.findIndex((notice) => notice.ntcId === Number(this.ntcId));
			if (this.noticeList[findIdx - 1]) {
				this.prevId = this.noticeList[findIdx - 1].ntcId;
			} else {
				this.prevId = 0;
			}

			if (this.noticeList[findIdx + 1]) {
				this.nextId = this.noticeList[findIdx + 1].ntcId;
			} else {
				this.nextId = 0;
			}
		},
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy-MM-dd');
		},
		atchDown() {
			if (this.params.atchFileUrl) {
				window.open(this.params.atchFileUrl, '_blank');
			}
		},
		onClickMoveInfo(ntcId) {
			this.$router.push({
				query: {
					...this.$route.query,
					ntcId,
				},
			});
		},
	},
};
</script>
