<template>
	<div>
		<!-- 개선된 공간 예약 안내 -->
		<section v-if="!isMobile" class="reserv-detail-notibox">
			<h4 class="noti-box-title">개선된 공간예약 이용안내</h4>
			<ol class="noti-box-list dot-list">
				<li>소상공인확인서, 사업자등록증명 제출이 간편해집니다.</li>
				<li>사이트별로 제공하는 강연 세미나 등 프로그램을 한눈에 살펴볼 수 있습니다.</li>
				<li>갤러리에서 공간 활용 우수사례를 찾아볼 수 있습니다.</li>
			</ol>
		</section>
		<!-- //개선된 공간 예약 안내 -->

		<div class="content">
			<div v-html="info.intro.contents"></div>

			<h3 v-if="info.intro.snsList.length > 0">관련 사이트 안내</h3>
			<ul>
				<li v-for="(sns,i) in info.intro.snsList" :key="sns.chnlId">{{sns.chnlTt}} : <a :href="sns.chnlUrl" target="_blank">{{ sns.chnlUrl }}</a></li>
			</ul>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'SpaceDetailIntro',
	components: {},
	props: {
		info: {
			type: Object,
			default: {},
		},
	},
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	mounted() {},
	data: () => ({}),
	mounted() {},
	methods: {},
};
</script>
