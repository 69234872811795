<template>
	<div>
		<div class="reserv-thumbnail-list">
			<button class="thumbnail-article" v-for="(item, idx) in reservList" :key="idx" @click="goRoomRsvt(item.spaceId, item.roomId)">
				<div class="thumb-default">
					<img :src="handleImageSrc(item.rprsImgFileUrl)" :alt="item.roomNm" @error="handleImgError" loading="lazy" />
				</div>
				<div class="thumbnail-article-text">
					<strong class="thumbnail-article-title">{{ item.roomNm }}</strong>
					<span class="thumbnail-article-desc">{{ item.intro }}</span>
				</div>
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_SPACE_ROOM_LIST } from '@/store/_act_consts';
import { MUT_SHOW_CONFIRM, MUT_SHOW_ALERT, MUT_SET_RETURN_ROUTE } from '@/store/_mut_consts';
import { lengthCheck, getItems } from '@/assets/js/utils';
import { getTokenByKey } from '@/assets/js/jwt.service';
import errorImg from '@/assets/img/common/error.png';

export default {
	name: 'ReservDetailReserv',
	components: {},
	props: {
		info: {
			type: Object,
			default: {},
		},
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
	},
	data: () => ({
		spaceId: 0,
		reservList: [],
	}),
	created() {
		this.spaceId = this.info.spaceId;
		// this.isLoginCheck();
		this.getSpaceRoomList();
	},
	mounted() {},
	methods: {
		handleImageSrc(src) {
			return src ? src : errorImg;
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
		isLoginCheck() {
			if (!getTokenByKey('auth_token')) {
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}

			return true;
		},
		goRoomRsvt(spaceId, roomId) {
			if (this.isLoginCheck()) this.$router.push({ name: 'SpaceRsvt', params: { spaceId: spaceId, roomId: roomId } });
		},
		getSpaceRoomList() {
			this.$store
				.dispatch(`space/${ACT_GET_SPACE_ROOM_LIST}`, {
					pageNo: 1,
					pageSize: 1000,
					spaceId: this.spaceId || 0,
					roomNm: '',
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.reservList = getItems(res);
					} else {
						this.reservList = [];
					}
				})
				.catch((e) => {
					console.error(e);
					this.reservList = [];
				});
		},
	},
};
</script>
