<template>
	<div>
		<section v-for="(glry, idx) in glryList" :key="idx" class="section-gallery">
			<div v-if="!isEmptyGlryDtlList(glry.glryDtlList)">
				<div class="section-gallery-header">
					<h3 class="section-gallery-header__title">{{ glry.glryDesc }}</h3>
				</div>
				<div class="section-gallery-body">
					<a :href="glryDtl.imgFileUrl" v-for="(glryDtl, idx1) in glry.glryDtlList" :key="idx1" @click.prevent="onClickShowImg(glry.glryDtlList, idx1)" class="section-gallery-item">
						<span class="thumb-default" :class="{ 'thumb-default-video': !!glryDtl.videoFileUrl ? true : false }">
							<img :src="glryDtl.imgFileUrl" :alt="glryDtl.imgFileDesc" @error="handleImgError" />
						</span>
					</a>
				</div>
			</div>
		</section>
		
		<space-gallery-swiper ref="gallerySwiper"></space-gallery-swiper>
	</div>
</template>

<script>
import SpaceGallerySwiper from '@/components/space/GallerySwiper.vue';	// 탭 - 갤러리 스와이퍼
import { 
	ACT_GET_SPACE_GLRY_AND_DTL_LIST,
} from '@/store/_act_consts';

import { getCheckItems } from '@/assets/js/utils';
import errorImg from '@/assets/img/common/error.png';

export default {
	name: 'SpaceGallery',
	components: {SpaceGallerySwiper},
	props: {
		info: {
			type: Object,
			default: {},
		},
	},
	watch: {
		// visible(newValue, oldValue) {
		// 	console.log('changed:', newValue, ' (old value:', oldValue, ')')
		// },
	},
	computed: {},
	data: () => ({
		glryList: [],
	}),
	mounted() {
		this.getGlryAndDtlList()
	},
	methods: {
		// event ===============================================================
		onClickShowImg(glryDtlList = [], idx = 0) {
			this.$refs.gallerySwiper.onClickShowImg(glryDtlList, idx)
		},

		// custom fn ===============================================================
		isEmptyGlryDtlList(dtlList = []) {
			return !dtlList || dtlList.length < 1
		},
		handleImgError(e) {
			e.target.src = errorImg;
		},

		// promise ===============================================================
		async getGlryAndDtlList() {
			const params = {
				spaceId: this.info.spaceId,
			}
            this.$store.dispatch(`space/${ACT_GET_SPACE_GLRY_AND_DTL_LIST}`, params)
			.then(res => {
				// console.log(["GlryAndDtlList"], res)
				this.glryList = getCheckItems(res)
			})
			.catch(console.error)
        },
	},
};
</script>
